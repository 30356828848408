<template>
  <SasModal
    class="exam-modal"
    footer-margin="15px 0 0 0"
    padding="0"
    :with-close-button="false"
    @close="$emit('close')"
  >
    <div
      slot="header"
      class="exam-modal__header"
    >
      <img
        alt="Start exam illustration"
        class="exam-modal__header_image"
        src="@/assets/assessment-exam-modal.svg"
      >
    </div>

    <div
      slot="body"
      class="exam-modal__body"
    >
      <h3>Antes de começar...</h3>

      <p class="exam-modal__body_description">
        Lembre-se, para concluir sua atividade você deve confirmar cada uma das suas respostas.
      </p>
    </div>

    <div
      slot="footer"
      class="exam-modal__footer"
    >
      <SasButton
        size="big"
        theme="float"
        @click="$emit('close')"
      >
        <SasIcon type="x" />
        Fechar
      </SasButton>

      <SasButton
        size="big"
        @click="$emit('start', exam)"
      >
        <a
          class="redirect"
          :href="redirectURL"
          rel="noopener noreferrer"
          target="_blank"
        >
          Começar
          <Icon
            slot="right"
            strike="white"
            type="arrow-right"
          />
        </a>
      </SasButton>
    </div>
  </SasModal>
</template>

<script>
import { getCookie } from '@/utils/cookies'
import urls from '@/utils/urls'

export default {
  name: 'AssessmentDetailsExamModal',
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  computed: {
    redirectURL() {
      const BASE_URL = `${urls.PORTAL_API}/redirects`
      const SESSION_TOKEN = getCookie('session')

      return `${BASE_URL}/studos?token=${SESSION_TOKEN}&redirect=student/central`
    },
  },
}
</script>

<style lang="sass">

.exam-modal

  .redirect
    display: flex
    align-items: center
    justify-content: center

  .modal
    box-sizing: border-box

    +mq-s
      min-width: auto
      padding: 16px

      .modal__close
        top: 8px
        right: 8px
        opacity: .6

  &__header
    background-image: url(~@/assets/blue-template-banner.png)
    max-height: 292px

  &__header-description
    margin-top: 8px

  &__header_image
    transform: translateY(-15px)

    +mq-s
      width: 100%
      transform: translateY(-10px)

  &__body
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-bottom: 10px

    &_description
      font-size: $font-size-s
      color: $color-ink_light
      margin-top: 10px
      max-width: 70%
      text-align: center

    &-details
      padding: 32px 40px
      position: relative
      z-index: 1

      &::after
        content: ""
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0

        +mq-xs
          background: linear-gradient(90deg, white 30%, transparent 100%)

      +mq-s
        padding: 8px

      &__info
        position: relative
        z-index: 1
        margin-bottom: 24px

        .info-right__label
          font-size: 13px

    &-ilustration
      width: 328px

      +mq-s
        position: absolute
        right: -100px
        width: 320px

      +mq-xs
        right: -125px
        width: 280px

  &__footer
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 1px solid $color-ink_lightest
    padding: 15px

    .btn--float
      text-decoration: none

</style>
